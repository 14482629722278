import React from 'react'
import { css } from '@emotion/react'
import { StaticLayout } from '../components/StaticLayout'
import { Footer } from '../components/Footer'
import styled from '@emotion/styled'
import { Typography } from 'antd'
const { Paragraph } = Typography

const styles = css`
  body {
    padding: 0;
    margin: 0;
    /*gradient light color for faq section*/
    background-color: #fdfaf4;
  }

  #navbar {
    height: 50px;
    border-bottom: 2px solid #ccc;
    clear: both;
    background-color: #eee;
  }

  #logolink {
    float: left;
    height: 38px;
    margin-top: 7px;
    margin-left: 10px;
  }

  #logo {
    height: 38px;
  }

  #close {
    float: right;
    margin-right: 10px;
    margin-top: 7px;
    text-decoration: none;
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    color: black;
    height: 40px;
    padding: 10px;
  }

  .static-content-background {
    padding: 0px 15px 15px 15px;
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-size: 14px;
    line-height: 1.4em;
  }

  h2 {
    text-align: center;
    margin-bottom: 20px;
    color: #000066;
    cursor: default;
    font-size: 30px;
    font-weight: 500;
    margin-top: 10px;
  }

  h3 {
    font-size: 22px;
    line-height: 1.4em;
    font-weight: 500;
    text-align: center;
  }

  h5 {
    text-align: center;
    font-size: 14px;
  }

  .contact-block {
    text-align: center;
    max-width: 500px;
    margin: auto;
  }
`

const AboutLink = styled.a`
  color: #fff;

  &:active,
  &:hover {
    color: #fff;
  }
`

const AboutUs = () => (
  <StaticLayout>
    <div css={styles}>
      <div id="navbar">
        <a id="logolink" href="https://www.stayfari.com">
          <img
            id="logo"
            src="https://ik.imagekit.io/stayfari/general/tr:w-160,h-48,c-maintain_ratio/FL_Norwester_Elbaris_Hybrid-01_LlKpdcqksp.png?ik-sdk-version=javascript-1.4.3&updatedAt=1666155398553"
            alt="Stayfari Logo"
          />
        </a>
        {/* <GetStartedButton /> */}
        <a id="close" href="javascript:window.close()">
          Close
        </a>
      </div>
      <div className="static-content-background">
        <h2>About Us</h2>
        <Paragraph className="contact-block">
          We are the highest-rated provider of chauffeured vehicles in India, Sri Lanka, Nepal and
          Mauritius. Cleanliness, safety and reliability are our bedrock principles for every trip.
          We invite you to join the thousands of satisfied customers who&#39;ve experienced the
          Stayfari difference!
        </Paragraph>
        <br />
        <Paragraph className="contact-block">
          <h4>Contact</h4>
          <a href="tel:+91 95132 49958">+91 95132 49958</a>
          <br />
          <AboutLink
            href="#"
            onClick={() =>
              window.open(
                'mailto:contact@stayfari.com?subject=Help requested&body=If you have an existing reservation, please include the name on your reservation and your travel dates.',
                '_self'
              )
            }
          >
            contact@stayfari.com
          </AboutLink>
          <br />
          <br />
          <h4>Address</h4>
          <div>
            Stayfari India Private Limited
            <br />
            512, DLF Tower B
            <br />
            Jasola, New Delhi-110025
          </div>
          <br />
          <iframe
            title="stayfari-headquarters"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15300320.291477209!2d71.02071869594977!3d20.57050001206744!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d1f3d08a8ceb1%3A0xfed0012301d70bd8!2sStayfari%20Inc!5e0!3m2!1sen!2sus!4v1694392471542!5m2!1sen!2sus"
            width="100%"
            height="300"
            style={{ border: 0 }}
            allowFullScreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </Paragraph>
      </div>
    </div>
    <Footer />
  </StaticLayout>
)

export default AboutUs
